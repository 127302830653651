import { TryRounded } from "@mui/icons-material"

export const domain = 'https://mfi-api.tis.coke.com'
export const azureClientId = 'd09388b2-aebc-4982-b7bd-cb81e241928b'
export const azureTenantId = '548d26ab-8caa-49e1-97c2-a1b1a06cc39c'
export const apiKey = 'SyvAFAUua29HhpuJCtRRt7xwYNAIAp9o4hApYNLB'
export const stage = 'prod'
export const featureFlags = {
  '764709': true,
  '792789': true,
  '1896027': true,
  authorization: true,
  cpkBottledWater: true,
  mfi: false
}